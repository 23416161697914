html,
body {
  height: 100% !important;
  background-color: #fff6cb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test {
  color: red;
  border: 1px solid red;
}

.clickable {
  cursor: pointer;
}

.gf-page {
  margin-top: 10px;
  max-width: 1000px;
  min-height: 85vh;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 4px;
}
