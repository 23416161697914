.container {
  align-items: center;
  height: 10vh;
}

.header-item {
  margin-top: 10px;
}

.header-item-mobile {
  margin-top: 10px;
}
